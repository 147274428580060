.solo-title-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    align-items: flex-start;
}
.solo-title-wrapper h3{
    margin: 0;
    font-size: 33px;
    font-weight: 200;
    text-transform: capitalize;
}

.solo-title-wrapper p {
    margin: 0.5% 0%;
    font-weight: 400;
    font-size: medium;
    text-transform: capitalize;
    flex-grow: 5;
}
.solo-content-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    /* height: 75.2em; */
    align-content: flex-start;
    align-items: flex-start;
}
.solo-content-wrapper p {
    width: 100%;
    text-align: justify;
    line-height: 23px;
    font-weight: 200;
    font-size: larger;
}



.iframe-wrapper{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    width: 100%;
    margin: auto;
    height: 100%;
    flex-wrap: wrap;
}

.iframe-wrapper img{
    margin-top: 1%;
    width: 64%;
}

.solo-yt-iframe{
    margin-top: 1%;
    width: 62.6%;
    height: 512px;
}

.bandCamp-iframe{
    width: 100%;
    /* height: 43.4em; */
    height: 42px;
    border: 0;
    /* background-color: black;*/
}
