h2 {
    margin: 0%;
}
nav{
    width: 7.5em;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-size: 23px;
}

ul{ 
    margin: 0%;
    padding: 0%;
}
ul li {
    list-style-type: none;
}

ul li:hover {
    font-weight: 600;
}

.socials{
    display: flex;
    flex-direction: row;
}